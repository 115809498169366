import React, { useEffect, useState, useRef, FC, ReactNode } from "react";
import sanityClient from "../../helpers/client";
import { InnerLink } from "../../components/links/";
import iconArrow from "../../assets/icons/icon_arrow.svg";
import styles from "./job.module.scss";
import icon_frontend from "../../assets/icons/icon_frontend.svg";
import icon_dackend from "../../assets/icons/icon_dackend.svg";
import YoutubeEmbed from "../../components/youtube-embed";
import MetaHeader from "../../components/metaheader";
import { CLOUDINARY_IMG } from "../../helpers/const";
import ValuePoint from "./value-point";
import Modal from "../../components/modal";
import SlickCarousel from "../../components/carousel";
import UnorderedList from "../../components/list/unordered-list";
import { NavLink } from "react-router-dom";
import Image from "../../components/image";

interface Props {
  metaDesc?: string;
  url?: string;
  pageTitle?: string;
  title?: string;
  subtitle?: ReactNode;
  socialMediaDesc?: string;
  ctaButtonText?: string;
  imageSrc?: string;
}

const JobPage: FC<Props> = ({
  metaDesc,
  url,
  pageTitle,
  title,
  subtitle,
  socialMediaDesc,
  ctaButtonText,
  imageSrc,
}) => {
  const jobListingsRef = useRef<HTMLInputElement>(null);
  const secondScreenRef = useRef<HTMLSpanElement>(null);
  const [pageInfo, setPageInfo] = useState<any>([]);
  const [allPostsData, setAllPosts] = useState<any>([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "jobb_page"]{
                title,
                subtitle,
                cta_button,
                job_opening,
                form_title,
                body,
            }`
      )
      .then((data) => setPageInfo(data))
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == "jobb_list"]{
            title,
            description,
            slug,
            categories[]->,
            mainImage {
              caption,
              asset->{
              _id,
              url
              }
            }
          }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    const script = document.createElement("script");

    script.src = "https://widget.gobistories.com/gwi/6";
    script.async = true;
    script.onload = () => {
      (window as any).gobi.discover();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function focusContactForm() {
    jobListingsRef.current?.scrollIntoView({ behavior: "smooth" });
    jobListingsRef.current?.focus();
  }

  function scrollToSecondScreen() {
    secondScreenRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <MetaHeader
        description={
          metaDesc
            ? metaDesc
            : "👨‍💻 👩‍💻 Jobb som utvikler hos Yne. Yne er et konsulentselskap som stadig søker flinke folk."
        }
        url={url ? url : "https://yne.no/jobb"}
        title={pageTitle ? pageTitle : "Jobbe | Yne"}
        socialMediaDescription={
          socialMediaDesc
            ? socialMediaDesc
            : pageInfo[0] && pageInfo[0].subtitle
        }
        imageUrl={imageSrc ? imageSrc : CLOUDINARY_IMG.JOB.party_group_image}
      />
      <div className={styles.video}>
        <div className={styles.video_text}>
          <h1>{title ? title : pageInfo[0] && pageInfo[0].title}</h1>
          {subtitle
            ? subtitle
            : pageInfo[0] && (
                <h2 className={styles.subtitle}>{pageInfo[0].subtitle}</h2>
              )}
          <button
            className={styles.scroll_to_joblist}
            onClick={focusContactForm}
          >
            {ctaButtonText
              ? ctaButtonText
              : pageInfo[0] && pageInfo[0].cta_button}
          </button>
        </div>
        <button
          className={styles.scroll_next_screen}
          onClick={scrollToSecondScreen}
        >
          <img src={iconArrow} alt="" />
        </button>
        <div className={styles.overlay}></div>
        <img src={imageSrc ? imageSrc : CLOUDINARY_IMG.JOB.hero_image} alt="" />
      </div>
      <span ref={secondScreenRef}></span>

      <div className={styles["background-one"]}>
        <div className={`section-container`}>
          <h2>Hva er greia med Yne?</h2>
          <div className={styles.values}>
            {/* <ValuePoint
              title="Flat struktur"
              imgsrc="https://res.cloudinary.com/yneno/image/upload/v1692954427/Jobb-Page/rekruttering2023/njaal-arne_wttv2l.jpg"
            >
              <Modal title="Flat struktur" buttonText="Les mer">
                <p>
                  Som de fleste andre selskap har Yne en administrasjon. Men som
                  utvikler i et konsulentselskap kan det noen ganger føles som
                  at man er en brikke uten påvirkningskraft. Slik er det ikke i
                  Yne.
                </p>
                <p>
                  Yne er av utviklere, for utviklere. Noe som betyr at selskapet
                  er stiftet av tre utviklere som ønsker å skape det beste
                  konsulentselskapet for utviklerne. De tre gründerne jobber
                  fortsatt som utviklere, selv om de også er del av
                  administrasjonen.
                </p>
                <p>
                  Hos oss sitter administrasjon og utviklere side om side i det
                  åpne landskapet. Det er også viktig for oss at utviklerne har
                  en løpende dialog med salg i forbindelse med nye oppdrag. Du
                  er orientert om hvilke prosesser du er del av, og du blir ikke
                  sendt inn på oppdrag uten at du ønsker og vet om det.{" "}
                </p>
              </Modal>
            </ValuePoint> */}
            <ValuePoint
              title="Sterkt faglig fokus"
              imgsrc="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693852349/Jobb-Page/rekruttering2023/IMG_3032_kopi_dxo1nb.jpg"
            >
              <Modal title="Sterkt faglig fokus" buttonText="Les mer">
                <UnorderedList>
                  <li>
                    Hos Yne brenner vi for fag og teknologisk kunnskap. Hos oss
                    vil du få muligheten til å dra på messe eller konferanse
                    både i Norge og i utlandet.{" "}
                  </li>
                  <li>
                    Vi har månedlige fagkvelder der du kan dele og lære med
                    andre engasjerte Yne medlemmer.
                  </li>
                  <li>
                    Om du ønsker å skrive et blogginnlegg vil dette gi deg
                    bonus!
                  </li>
                  <li>
                    Vi ønsker å ha faggrupper i Yne, og engasjerte utviklere har
                    dannet en intern gruppe kalt Yne-labs! Her kan du leke deg
                    med innovasjon, fag og kreativitet. Bli med da vel!{" "}
                  </li>
                </UnorderedList>
                <Image
                  src={
                    "https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693852350/Jobb-Page/rekruttering2023/IMG_3060_eohawu.jpg"
                  }
                />{" "}
              </Modal>
            </ValuePoint>

            <ValuePoint
              title="Deleier"
              imgsrc="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1692956882/Jobb-Page/rekruttering2023/deleier_h9mqso.jpg"
            >
              <Modal title="Deleier" buttonText="Les mer">
                <p>
                  {" "}
                  Ønsker du ikke bare en jobb, men også en sjanse til å påvirke
                  og vokse med selskapet du jobber for? Yne tilbyr mer enn bare
                  en lønnsslipp: vi tilbyr en unik mulighet til å bli deleier i
                  vår suksess. Som et verdifullt medlem av Yne-familien får du
                  eksklusiv tilgang til å kjøpe aksjer i selskapet til en
                  spesialpris. Dette gir deg en gylden anledning til å investere
                  i din og Ynes fremtid, samtidig som du har en aktiv rolle i
                  selskapets utvikling.
                </p>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693853055/Jobb-Page/rekruttering2023/Skjermbilde_2023-09-04_kl._20.43.52_szkgrh.png" />
                <p>
                  Når du blir aksjonær, åpnes dørene til våre
                  generalforsamlinger, der din stemme ikke bare blir hørt, men
                  også verdsatt. Du får muligheten til å påvirke veivalgene vi
                  tar, nettopp fordi vi mener de beste ideene kommer fra teamet
                  som bygger selskaper. I tillegg vil økningen i selskapets
                  verdi reflektere verdien av dine investeringer. Og som prikken
                  over i-en blir du selvfølgelig inkludert når det er tid for å
                  dele utbyttet. Bli på en reise der innsatsen din ikke bare
                  løfter karrieren, også gir deg en bit av suksessen!
                </p>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1692954304/Jobb-Page/rekruttering2023/MNF_9257_tsjefk.jpg" />
              </Modal>
            </ValuePoint>

            <ValuePoint
              title="Form verden med Yne"
              imgsrc="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1692954304/Jobb-Page/rekruttering2023/vegard-andreas_wspx4q.jpg"
            >
              <Modal
                title="Bli Arkitekten av Din Fremtid: Form verden med Yne"
                buttonText="Les mer"
              >
                <p>
                  Velkommen til Yne – der innovasjon og utviklerkultur blomstrer
                  i hjertet av vår virksomhet. Vi er ikke bare et
                  IT-konsulentselskap; vi er en familie av lidenskapelige
                  skapere, forenet av en felles drøm om å revolusjonere
                  teknologibransjen fra innsiden. Grunnlagt og drevet av
                  utviklere, for utviklere, er Yne stedet hvor din stemme ikke
                  bare høres, men aktivt former fremtiden til selskapet.{" "}
                </p>
                <p>
                  I et landskap hvor mange føler seg som en liten brikke i et
                  stort maskineri, tilbyr Yne en unik mulighet. Hos oss blir du
                  en integrert del av et team hvor dine ideer og visjoner ikke
                  bare blir verdsatt, men er essensielle for vår felles reise.
                  Vi tror på kraften av samarbeid og at den beste måten å
                  forutsi fremtiden på, er å oppfinne den sammen.
                </p>
                <p>
                  Vår flate organisasjonsstruktur er ikke bare en del av vår
                  filosofi; den er nøkkelen til vår agilitet og innovasjonsevne.
                  Kort vei mellom ledelse og ansatte sikrer at dine tanker og
                  ideer raskt kan tas fra konsept til virkelighet. Hos Yne er du
                  ikke en tilskuer til endring; du er en arkitekt av den. Med
                  full kontroll over vår kurs kan vi sammen navigere mot nye og
                  spennende destinasjoner, drevet av teknologi, kreativitet og
                  vår ubendige vilje til å gjøre en forskjell.
                </p>
                <p>
                  Vi søker ikke bare etter ansatte; vi søker etter visjonære som
                  deler vår lidenskap for utvikling og ønsker å bygge en
                  arbeidsplass hvor hver enkelt kan blomstre og være stolt. Din
                  evne til å påvirke og forme selskapet er ikke bare en
                  mulighet; det er en forventning. Ved å bli en del av Yne, blir
                  du en del av et miljø hvor vekst, læring og innovasjon står i
                  sentrum.
                </p>
                <p>
                  Bli med oss på denne reisen. La oss sammen skape en fremtid
                  hvor teknologi og menneskelighet går hånd i hånd, og hvor
                  arbeidsplassen blir et sted for inspirasjon, utfordring og
                  stolthet. Yne venter på deg – ta skrittet inn i en verden hvor
                  din stemme former morgendagens teknologilandskap.
                </p>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693853056/Jobb-Page/rekruttering2023/Skjermbilde_2023-09-04_kl._20.40.45_ha1cvl.png" />
              </Modal>
            </ValuePoint>

            <ValuePoint
              title="Provisjonsbasert lønn"
              imgsrc="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1692954325/Jobb-Page/rekruttering2023/l%C3%B8nn_vpljdb.png"
            >
              <Modal title="Provisjonsbasert lønn" buttonText="Les mer">
                <p>
                  Alle arbeidstakere fortjener en rettferdig lønn. Hos Yne gir
                  vi våre ansatte provisjonslønn basert på en prosentandel av
                  omsetningen. Vår lønnsmodell er transparent, slik at det
                  alltid er klart hva du tjener.
                </p>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693853056/Jobb-Page/rekruttering2023/Skjermbilde_2023-09-04_kl._20.41.59_kph3wh.png" />
                <p>
                  Din lønn påvirkes direkte av den verdien du skaper for
                  selskapet. Jobber du overtid, blir lønnen høyere. Øker din
                  erfaring og gjør deg mer ettertraktet med en god profil og
                  fornøyde kunder, vil dette påvirke hvor mye du bidrar til
                  selskapet, og dermed også lønnen din.
                </p>
                <p>
                  Du trenger ikke å bekymre deg for lønnsforhandlinger hos Yne -
                  lønnen beregnes direkte ut fra det fakturerte beløpet. Du kan
                  gjerne sjekke ut vår lønnskalkulator{" "}
                  <NavLink to={"/lonnskalkulator"}>lønnskalkulator</NavLink> for
                  å se alle detaljene.{" "}
                </p>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693854030/Jobb-Page/rekruttering2023/njaal-arne_wttv2l_kopi_gzql69.jpg" />
              </Modal>
            </ValuePoint>

            <ValuePoint
              title="Kontoret vårt ❤️"
              imgsrc="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1692954304/Jobb-Page/rekruttering2023/david-shuffle_secdh3.jpg"
            >
              <div></div>
              <Modal title="Kontor" buttonText="Les mer">
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693853247/Jobb-Page/rekruttering2023/Grensen15__05_2_kopi_n9t0lq.jpg" />
                <figcaption> Foto: Anne Bråtveit</figcaption>
                <p>
                  Kontoret vårt på Grensen 15 er vårt samlingssted der alle skal
                  føle seg velkomne, både i arbeidstiden og på fritiden. Alle
                  Yne-medlemmer kan booke lokalet til private arrangementer som
                  bursdager, fest eller spillkvelder. Vi har nylig pusset opp
                  hele lokalet i samarbeid med et arkitektfirma for å skape en
                  hyggelig atmosfære, og ønsker at lokalet skal bli brukt til å
                  skape gode minner. Kontoret tilbyr blant annet:
                </p>
                <UnorderedList>
                  <li>Shuffleboard</li>
                  <li>Et kjøleskap som alltid er fullt av gode drikkevarer</li>
                  <li>
                    Ekstremt god beliggenhet, bare ett minutt gange fra
                    Stortinget t-banestasjon{" "}
                  </li>
                  <li>Takterasse med utsikt over byen</li>
                </UnorderedList>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693853057/Jobb-Page/rekruttering2023/Skjermbilde_2023-09-04_kl._20.41.28_odw6vk.png" />
              </Modal>
            </ValuePoint>

            <ValuePoint
              title="Godt sosialt miljø"
              imgsrc="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1692955057/Jobb-Page/rekruttering2023/sosialt_j3ihpv.jpg"
            >
              <Modal title="Godt sosialt miljø" buttonText="Les mer">
                <p>
                  Det sosiale står veldig sterkt i Yne. Som konsulent ser ikke
                  alle hverandre daglig, da mange jobber ute hos kundene. Derfor
                  arrangerer vi månedlige sosial events i tillegg til månedlige
                  fagkvelder. Det er viktig for oss å ha et internt fagmiljø
                  hvor vi kan utvikle oss sammen, og vi tror dette er lettere
                  dersom vi kjenner hverandre godt!{" "}
                </p>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693859793/Jobb-Page/rekruttering2023/DSC09305_qxladg.jpg" />
                <p>
                  I Yne er vi lekne og det er viktig for oss å ha det moro
                  sammen med Yne familien - rett og slett fordi vi liker å være
                  sammen!{" "}
                </p>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693859753/Jobb-Page/rekruttering2023/IMG_3692_2_kopi_tdpd08.jpg" />
                <p>
                  Gjennom året arrangerer vi en rekke hyggelige aktiviteter
                  sammen, og vi har en egen sosialgruppe som planlegger og
                  inviterer til samlinger. På starten av året lager vi et
                  sosialhjul, slik at så mange som mulig kan planlegge å delta
                  på flest mulig aktiviteter. Våre sosiale arrangementer har
                  høyt oppmøte og vi har allerede skapt mange gode minner
                  sammen. Vi ser frem til å skape enda flere! Noen eksempler på
                  ting vi har gjort så langt:
                </p>
                <UnorderedList>
                  <li>Hyttetur</li>
                  <li>Julebord og julelunsj</li>
                  <li>Sommerfest med partner</li>
                  <li>Gokart</li>
                  <li>Bar til bar rebus </li>
                  <li>Sauna</li>
                  <li>Escape room</li>
                </UnorderedList>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693859799/Jobb-Page/rekruttering2023/AT9A9383_kopi_erbri2.jpg" />
                <p>
                  {" "}
                  I tillegg har vi mindre ting som bare noen er med på, som
                  blant annet:
                </p>
                <UnorderedList>
                  <li>Squash</li>
                  <li>Malekveld</li>
                  <li>Tur med friluftsgruppa</li>
                  <li>Gaming med CS gruppa</li>
                </UnorderedList>
                <Image src="https://res.cloudinary.com/yneno/image/upload/w_1000/f_auto/q_auto/v1693859921/Jobb-Page/rekruttering2023/MNF_9244_3_kopi_v2npyl.jpg" />
              </Modal>
            </ValuePoint>
          </div>
        </div>
      </div>

      <div className={styles["background-two"]}>
        <div className={`section-container `}>
          <h2>På innsiden av Yne</h2>
          <div>
            <h3>Hva sier ansatte i Yne?</h3>
            <p
              className={"gobi-stories " + styles.gobi}
              data-gobi-stories="pz2ee 97sfv fo46b 49vjd"
              data-gobi-color="#6105f8"
              data-gobi-bubble-size="125px"
              data-gobi-animated-bubble="true"
              data-gobi-title-font-color="#FFFFFF"
              data-gobi-disable-shadow-dom="false"
              data-gobi-inherit-text-font-family="true"
              data-gobi-auto-start-with-sound="true"
            ></p>
          </div>
          <div className={`${styles["video-carusel-wrapper"]}`}>
            <div className={styles.carusel}>
              <SlickCarousel />
            </div>
            <div className={styles.ytvideo}>
              <YoutubeEmbed embedId="5_iWiPHilaM" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["background-three"]}>
        <div
          className={`${styles.positions} section-container`}
          ref={jobListingsRef}
        >
          <h2>{pageInfo[0] && pageInfo[0].job_opening}</h2>
          {allPostsData &&
            allPostsData.map((post: any) => (
              <div key={post.slug.current} className={styles.job_wrapper}>
                {post.categories && post.categories[0].title === "Frontend" ? (
                  <img src={icon_frontend} alt="" />
                ) : (
                  <img src={icon_dackend} alt="" />
                )}

                <div className={styles.job_info}>
                  <h3>{post.title}</h3>
                  <p>{post.description}</p>
                  <div className={styles.details}>
                    <InnerLink
                      link={"/jobb/" + post.slug.current}
                      name="Detaljer"
                      withArrow
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default JobPage;
