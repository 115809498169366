import React, { useEffect } from "react";
import BlogFeed from "../../components/blog-feed";
import UnorderedList from "../../components/list/unordered-list";
import MetaHeader from "../../components/metaheader";
import { CLOUDINARY_IMG } from "../../helpers/const";
import { ProjectList } from "../projects/components/project-list";
import { ReactComponent as VerdierImage } from "../../assets/images/verdier.svg";
import styles from "./about-us.module.scss";
import YoutubeEmbed from "../../components/youtube-embed";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/buttons/primary-button";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <MetaHeader
        description="I Yne er vi gode på mye, men vi vil være best på systemutvikling. Hos oss får man jobbe med kunder i mange bransjer, men felles er at de trenger en som kan håndtere komplekse oppgaver med stor trygghet, solid fagkompetanse og en fremoverlent holdning. Det kan en Yne! Les mer om hvem Yne er her."
        url="https://yne.no/om-oss"
        title="Hvem er Yne? | Yne"
        socialMediaDescription="Yne er en gjeng engasjerte FOLK som er møter hverandre med åpenhet, godt samhold og en flat struktur på arbeidsplassen!"
        imageUrl={CLOUDINARY_IMG.HOME.team_party}
      />
      <div className={`${styles.about}`}>
        <div>
          <section className={`section-container ${styles.leadImage}`}>
            <VerdierImage />
          </section>

          <div style={{ backgroundColor: "#e6f4ed" }}>
            <section className="section-container">
              <div className={styles.summary}>
                <div>
                  <h1 className={styles.title}>Av utviklere, for utviklere</h1>
                  <p>
                    I Yne er vi gode på mye, men vi vil være best på
                    systemutvikling. Hos oss får du erfarne utviklere som kan
                    håndtere komplekse oppgaver med stor trygghet, solid
                    fagkompetanse og en fremoverlent holdning.
                  </p>
                </div>
                <div className={styles.keyInfo}>
                  <div>
                    <h3>32 år</h3>
                    <p>Snittalder</p>
                  </div>
                  <div>
                    <h3>2021</h3>
                    <p>etablert</p>
                  </div>
                  <div>
                    <h3>55%</h3>
                    <p>andel med mastergrad</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section
          className={`section-container ${styles.lists} ${styles.sectionPadding}`}
        >
          <h2>Oss, kort oppsummert</h2>
          <UnorderedList>
            <li>Svært dyktige IT-konsulenter</li>
            <li>Motiverte ansatte som trives</li>
            <li>Selskap i vekst</li>
          </UnorderedList>
          <UnorderedList>
            <li>Erfaring fra et bredt spekter av bransjer</li>
            <li>Faglig oppdatert</li>
            <li>Delingskultur</li>
          </UnorderedList>
        </section>

        {/* <div
          style={{ backgroundColor: "#f2fcfd" }}
          className={styles.sectionPadding}
        >
          <section id="folk" className={`section-container`}>
            <p
              style={{
                maxWidth: 750,
                margin: "auto",
                textAlign: "center",
              }}
            >
              Vi leverer verdi til kunden gjennom engasjerte ansatte med faglig
              interesse og dybde. Derfor investerer vi i våre ansatte via
              konferanser, kurs og sosiale sammenkomster kombinert med faglig
              innhold. Her kan du lese litt om våre dyktige konsulenter.
            </p>
            <div style={{ margin: "4rem" }} className={styles.separator}>
              <div />
              <div />
            </div>
            <h2>Hvem er vi?</h2>
            <EmployeeGallery />
          </section>
        </div> */}

        <div
          className={styles.sectionPadding}
          style={{ backgroundColor: "#F4F4F4" }}
        >
          <section className="section-container">
            <h2>Prosjekter</h2>
            <h3>Kundereferanse: Axo Finans</h3>
            <YoutubeEmbed embedId="EiaLQ27G9OY" />
            <div className={styles["project-list"]}>
              <h3>Noen av våre prosjekter</h3>

              <ProjectList
                columns={3}
                limit={3}
                sortFn={() => 0.5 - Math.random()}
                headerAs={"h4"}
              />
            </div>

            <Link to={"/prosjekter"} className={styles.projects}>
              <PrimaryButton size="large" onClick={() => null}>
                {"Flere prosjekter"}
              </PrimaryButton>
            </Link>
          </section>
        </div>

        <div className={styles.sectionPadding}>
          <section className="section-container">
            <h2>Siste fra bloggen</h2>
            <BlogFeed />
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
